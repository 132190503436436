.talentList {
  width: 100%;
  padding-top: 1.226667rem;
}

.talentList .help-button {
  width: 2.666667rem;
  height: 1.226667rem;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

.talentList .help-button>div {
  height: 0.853333rem;
  line-height: 0.373333rem;
  width: 2.133333rem;
  background: linear-gradient(267deg, #1990ff 0%, #2c25ff 100%);
  border-radius: 0.426667rem;
  margin-top: 0.266667rem;
  padding: 0.053333rem 0 0 0.266667rem;
  box-sizing: border-box;
  vertical-align: middle;
  position: relative;
  right: 0.533333rem;
}

.talentList .help-button>div>span {
  display: inline-block;
  width: 0.8rem;
  font-size: 0.32rem;
}

.talentList .help-button>div i {
  margin-right: 0.053333rem;
  position: relative;
  top: -0.16rem;
}

.talentList .help-button>img {
  float: left;
  margin-top: -0.266667rem;
  position: relative;
  z-index: 10;
  left: -0.533333rem;
}

.talentList .float-wrapper {
  padding: 0.4rem 0;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.talentList .float-wrapper .float-item .float-item-text {
  text-align: center;
}

.talentList .test {
  width: 4rem;
  height: 4rem;
  position: fixed;
  background-color: cadetblue;
  bottom: 3rem;
  left: 1rem;
}

.talentList .head {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 10;
  box-shadow: 0 0.053333rem 0.053333rem 0 rgba(120,120,120,0.1);
}

.talentList .head .tab-wrapper {
  width: 100%;
  display: flex;
  font-size: 0.4rem;
}

.talentList .head .tab-wrapper .tab {
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.talentList .head .tab-wrapper .tab .van-dropdown-menu__bar {
  box-shadow: none;
}

.talentList .head .tab-wrapper .active {
  color: #1f93fd;
}

.talentList .head .tab-wrapper .active .van-dropdown-menu__title {
  color: #1f93fd;
}

.talentList .head .tip-bar {
  text-align: center;
  padding: 0.1rem 0;
  background-color: #e3f3ff;
  color: #1f93fd;
  font-size: 0.32rem;
}

.talentList .list-wrapper {
  width: 100%;
}

.talentList .list-wrapper .right-slot {
  width: 50%;
}

.talentList .list-wrapper .right-slot .row1 {
  font-size: 0.32rem;
  text-align: right;
  padding-right: 0.5rem;
}

.talentList .list-wrapper .right-slot .row2 {
  font-size: 0.32rem;
  text-align: right;
  margin-top: 0.266667rem;
  padding-right: 0.5rem;
}

.talentList .nocandidate {
  width: 80%;
  margin: 0.533333rem auto;
}

.talentList .nocandidate img {
  width: 100%;
}

.talentList .nocandidate .text {
  margin-top: 0.533333rem;
  color: #666666;
  text-align: center;
  font-size: 0.533333rem;
}

.talentList .van-popup--right {
  width: 80%;
  height: 100%;
}

.video-popup .el-dialog__body {
  padding: 0;
}

.video-popup .el-dialog__header {
  display: none;
}