.info {
  width: 100%;
  padding-top: 0.266667rem;
  margin-bottom: 0.266667rem;
}

.info .detail-wrapper {
  display: flex;
  margin: 0 0.533333rem;
  padding: 0 0 0.266667rem 0;
  border-bottom: 0.026667rem solid #dddddd;
}

.info .detail-wrapper .left {
  width: 4.8rem;
}

.info .detail-wrapper .left .row1 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.373333rem;
}

.info .detail-wrapper .left .row1 .name {
  font-size: 0.4rem;
  font-weight: 600;
}

.info .detail-wrapper .left .row1 .age {
  font-size: 0.37rem;
  margin-left: 0.2rem;
  color: #333333;
}

.info .detail-wrapper .left .row1 .channel {
  margin-left: 0.2rem;
  width: 0.4rem;
  height: 0.4rem;
}

.info .detail-wrapper .left .row1 .channel img {
  border-radius: 50%;
  width: 100%;
}

.info .detail-wrapper .left .row1 .channelName {
  font-size: 0.4rem;
  margin-left: 0.2rem;
  color: #333333;
}

.info .detail-wrapper .left .row1 .gender {
  margin-left: 0.2rem;
  width: 0.3rem;
}

.info .detail-wrapper .left .row1 .gender img {
  width: 100%;
}

.info .detail-wrapper .left .row2 {
  font-size: 0.373333rem;
  display: flex;
  color: #b3b3b3;
  flex-wrap: wrap;
}

.info .detail-wrapper .left .row2 div:not(:first-of-type):before {
  content: '|';
  padding: 0 0.106667rem;
}

.info .detail-wrapper .left .row3 {
  font-size: 0.373333rem;
  margin-top: 0.106667rem;
  color: #b3b3b3;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.info .detail-wrapper .left .row4 {
  font-size: 0.373333rem;
  margin-top: 0.106667rem;
  color: #b3b3b3;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.info .detail-wrapper .right {
  flex: 1;
  padding-left: 0.266667rem;
}

.info .detail-wrapper .right .row1 {
  margin-bottom: 0.373333rem;
  height: 0.56rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.info .detail-wrapper .right .row1 .icon-wrapper {
  display: flex;
  align-items: center;
  width: 1.493333rem;
  height: 0.533333rem;
  border-radius: 0.266667rem;
}

.info .detail-wrapper .right .row1 .icon-wrapper .img-wrapper {
  width: 0.533333rem;
  height: 0.533333rem;
  margin-left: -0.026667rem;
  margin-top: 0.026667rem;
}

.info .detail-wrapper .right .row1 .icon-wrapper .img-wrapper img {
  width: 100%;
}

.info .detail-wrapper .right .row1 .icon-wrapper i {
  margin-left: -0.053333rem;
}

.info .detail-wrapper .right .row1 .icon-wrapper .icon-text {
  margin-left: 0.106667rem;
}

.info .detail-wrapper .right .row1 .pass {
  color: #1890ff;
  border: 0.026667rem solid #1890ff;
}

.info .detail-wrapper .right .row1 .reject {
  color: #f04d4d;
  border: 0.026667rem solid #f04d4d;
}

.info .detail-wrapper .right .row1 .wait {
  color: #f7b500;
  border: 0.026667rem solid #f7b500;
}

.info .detail-wrapper .right .no-data {
  width: 100%;
  align-self: center;
  text-align: center;
  font-size: 0.346667rem;
  color: #aaaaaa;
}

.info .detail-wrapper .right .ai-score-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.info .detail-wrapper .right .ai-score-wrapper .score-container .model-img {
  color: #aaaaaa;
}

.info .detail-wrapper .right .ai-score-wrapper .score-container .model-img img {
  width: 1.2rem;
}

.info .detail-wrapper .right .ai-score-wrapper .score-container .score-text {
  font-size: 0.373333rem;
  color: #aaaaaa;
  height: 0.426667rem;
  line-height: 0.426667rem;
  margin-bottom: 0.053333rem;
  white-space: nowrap;
}

.info .detail-wrapper .right .ai-score-wrapper .score-container .van-rate__item {
  padding-right: 0;
}

.info .detail-wrapper .right .ai-score-wrapper .score-container:first-child {
  margin-bottom: 0.053333rem;
}

.info .detail-wrapper .right .ai-score-wrapper .left-score-wrapper {
  margin-right: 0.133333rem;
}

.info .aiData-wrapper {
  width: 90%;
  margin: 0.5rem auto 0 auto;
  background-color: #fafafa;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  color: #999999;
  font-size: 0.32rem;
}

.info .aiData-wrapper .aiData-item {
  width: 50%;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.2rem;
  align-items: center;
}

.info .aiData-wrapper .aiData-item .score .vip-img {
  max-width: 2.2rem;
}

.info .aiData-wrapper .aiData-item .score .vip-img img {
  width: 100%;
}

.inscoring {
  color: #daecfe;
}